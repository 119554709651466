<template>
    <b-modal
      v-model="data.showModal"
      size="lg"
      button-size="sm"
      modal-class="template-modal"
      title-class="w-full"
      footer-class="w-full"
      no-fade
      @show="onShow"
      hide-header-close
      scrollable
    >
      <!-- Modal header -->
      <div slot="modal-title">
        <div class="flex w-full justify-between">
          <div class="flex justify-between w-full items-center">
            <div v-if="title" class="text-xl t">
              {{ title }}
            </div>
            <div>SDS Products</div>
          </div>
        </div>
      </div>

      <!-- Modal content -->
      <div class="flex flex-col gap-2">
        <div v-if="isLoading && sds === null">
          Loading..
        </div>

        <table v-if="sds" class="table table-google">
          <thead>
            <tr>
              <th></th>
              <th>Product</th>
              <th>Grams</th>
              <th>Percentage</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item,index) in sds" :key="`sds_${index}`">
              <td class="text-nowrap">{{ item.title }}</td>
              <td class="text-nowrap">{{ item.product }}</td>
              <td class="text-right">
                <span class="text-nowrap">{{ roundNumber(item.grams, 5, 5) }}</span>
              </td>
              <td class="text-right">

                <b-button
                  variant="link"
                  @click="copyPercentage(item)"
                >
                  <span class="text-nowrap">{{ roundNumber(item.pct, 5, 5) }}</span>
                </b-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Modal footer -->
      <div slot="modal-footer" class="w-full d-flex gap-4 justify-content-between items-center">
        <div class="flex w-full justify-content-between">
          <div>
          </div>
          <div>
            <b-btn
              size="sm"
              variant="white"
              :disabled="isLoading"
              @click="onClose"
            >
              Close
            </b-btn>
          </div>
        </div>
      </div>
    </b-modal>
  </template>

<script>
import { roundNumber } from '@/helpers';

export default {
  name: 'SdsModal',
  components: {},
  props: {
    data: Object,
  },
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    },
    canSave() {
      return true;
    },
  },
  data() {
    return {
      loadingCount: 0,
      filters: {},
      options: {},
      title: null,
      info: null,
      sds: null,
    };
  },
  methods: {
    processTitle() {
      const colorantTexts = this.info.fragrance_text.split(' - ');
      const colorantText = colorantTexts.length > 1 ? colorantTexts[1] : colorantTexts[0];
      const candleBrand = this.info.candle_brand_text;
      this.title = `${candleBrand} - ${colorantText}`;
    },
    fetchInfo() {
      this.loadingCount++;
      this.$http
        .get(`/oilwax/${this.data.id}`)
        .then((res) => {
          this.info = res.body.record;
          this.processTitle();
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch info: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchSds() {
      this.loadingCount++;
      this.$http
        .get(`/oilwax/${this.data.id}/sds`)
        .then((res) => {
          this.sds = res.body;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch SDS: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchAll() {
      this.fetchInfo();
      this.fetchSds();
    },
    onSave() {},
    onShow() {
      this.fetchAll();
    },
    onClose() {
      this.data.showModal = false;
      this.$emit('close');
    },
    roundNumber(value, minimumFractionDigits, maximumFractionDigits) {
      return roundNumber(value, minimumFractionDigits, maximumFractionDigits);
    },
    async copyPercentage(item) {
      const text = roundNumber(item.pct, 5, 5);
      try {
        await navigator.clipboard.writeText(text);
        this.$toast.success(`${item.title} ${text}% copied to clipboard`);
      } catch (err) {
        this.$toast.error(`Clipboard error: ${err}`);
      }
    },
  },
};
</script>

<style lang="scss">
</style>
